import { sendGAEvent, sendGALinkClickEvent } from '@noths/polaris-client-google-analytics';

export type MembershipBannerLocation = 'search' | 'partner' | 'category';

interface TrackingArgs {
  contentType: string;
  label: string;
  location: MembershipBannerLocation;
}

export const trackImpression = ({ contentType, label, location }: TrackingArgs) => {
  sendGAEvent({
    event: 'custom_event',
    event_action: 'Impression',
    event_category: 'Membership banner',
    event_label: label,
    event_name: 'view_content',
    content_type: contentType,
    membership_banner_location: location,
  });
};

export const trackLinkClick = (
  e: React.MouseEvent<HTMLAnchorElement>,
  { contentType, label, location }: TrackingArgs,
) => {
  sendGALinkClickEvent(e, {
    event: 'custom_event',
    event_action: 'CTA click',
    event_category: 'Membership banner',
    event_label: label,
    event_name: 'select_content',
    event_details: 'cta click',
    content_type: contentType,
    membership_banner_location: location,
  });
};
