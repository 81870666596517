/* istanbul ignore file */
import React from 'react';
import { sendGALinkClickEvent } from '@noths/polaris-client-google-analytics';
import type { BreadcrumbLinkClickPayload } from '@noths/polaris-client-next-components';
import { Breadcrumbs } from '@noths/polaris-client-next-components';

import type { BreadcrumbsContainerStateProps } from './types';

export const BreadcrumbsContainer = ({ breadcrumbsLinks }: BreadcrumbsContainerStateProps) => {
  const onLinkClick = (
    e: React.MouseEvent | React.MouseEvent<HTMLAnchorElement>,
    { linkDepth }: BreadcrumbLinkClickPayload,
  ) => {
    return sendGALinkClickEvent(e, {
      event: 'custom_event',
      event_action: 'Link Click',
      event_category: 'Breadcrumb',
      event_label: `${linkDepth} Level(s) Up`,
      destination_URL: (e.currentTarget as HTMLAnchorElement).href,
    });
  };

  return <Breadcrumbs links={breadcrumbsLinks} onLinkClick={onLinkClick} />;
};
