/* istanbul ignore file */

import React from 'react';
import type { PillBarItemClickPayload } from '@noths/polaris-client-ribbons-design-system';
import { PillBarCarousel } from '@noths/polaris-client-ribbons-design-system';

import * as styles from 'src/components/organisms/Filter/styles/QuickFilters.styles';
import type {
  BrowseDataAPIFilter,
  BrowseDataAPIFilterOption,
} from 'src/services/browse-data-api/types/BrowseDataAPIFilter';

export interface OnClickOptionArgs {
  clickedOption: BrowseDataAPIFilterOption;
}

export interface QuickFiltersStateProps {
  filter: BrowseDataAPIFilter;
  onVisible?: () => void;
}

export interface QuickFiltersDispatchProps {
  onClickOption: (onClickOptionArgs: OnClickOptionArgs) => void;
}

export type QuickFiltersProps = QuickFiltersStateProps & QuickFiltersDispatchProps;

export const QuickFilters = ({ filter, onClickOption, onVisible }: QuickFiltersProps) => {
  const items = filter.options.map((option) => {
    return {
      text: option.title!,
      active: option.active,
    };
  });

  const handleOptionClick = (
    evt: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
    { index }: PillBarItemClickPayload,
  ) => {
    onClickOption({
      clickedOption: filter.options[index],
    });
  };

  return (
    <div css={styles.quickFilters}>
      <PillBarCarousel
        items={items}
        onItemClick={handleOptionClick}
        onVisible={onVisible}
        title="Quick filters"
      />
    </div>
  );
};
