/* istanbul ignore file */

import { batch, connect } from 'react-redux';
import { sendGAEvent } from '@noths/polaris-client-google-analytics';

import type {
  OnClickOptionArgs,
  QuickFiltersDispatchProps,
  QuickFiltersStateProps,
} from 'src/components/organisms/Filter/components/QuickFilters/QuickFilters';
import { QuickFilters } from 'src/components/organisms/Filter/components/QuickFilters/QuickFilters';
import { FilterUid } from 'src/components/organisms/Filter/constants/uids';
import { selectFilterByUid } from 'src/components/organisms/Filter/modules/selectors/selectors';
import { actions as filterActions } from 'src/components/organisms/Filter/modules/slice';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { updateFilters, UpdateFiltersActionType } from 'src/redux/products/thunks/updateFilters';
import type { AppThunkDispatch } from 'src/redux/store';

const mapStateToProps = (state: ReduxApplicationState): QuickFiltersStateProps => {
  return {
    filter: selectFilterByUid(state, FilterUid.Delivery)!,
    onVisible: () => {
      sendGAEvent({
        event: 'custom_event',
        event_category: 'Quick filters',
        event_action: 'quick filter impression',
        event_label: 'delivery',
      });
    },
  };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch): QuickFiltersDispatchProps => ({
  onClickOption: ({ clickedOption }: OnClickOptionArgs) => {
    sendGAEvent({
      event: 'custom_event',
      event_action: `${clickedOption.active ? 'de-select' : 'select'} quick filter delivery`,
      event_category: 'Quick filters',
      event_label: clickedOption.title,
    });

    batch(() => {
      dispatch(
        filterActions.changeFilterOption({
          uid: FilterUid.Delivery,
          options: [
            {
              active: !clickedOption.active,
              title: clickedOption.title,
              value: clickedOption.value,
            },
          ],
        }),
      );
      dispatch(updateFilters(UpdateFiltersActionType.FILTER));
    });
  },
});

export const QuickFiltersContainer = connect(mapStateToProps, mapDispatchToProps)(QuickFilters);
