import React from 'react';
import type { MembershipBannerProps } from '@noths/polaris-client-ribbons-design-system';
import { MembershipBanner } from '@noths/polaris-client-ribbons-design-system';

import type { MembershipBannerLocation } from 'src/tracking/events/membershipBannerEvents';
import { trackImpression, trackLinkClick } from 'src/tracking/events/membershipBannerEvents';
import * as styles from './MembershipBannerSection.styles';

interface MembershipBannerSectionProps extends MembershipBannerProps {
  trackingLocation: MembershipBannerLocation;
}

export const MembershipBannerSection = ({
  buttonHref,
  buttonText,
  copy,
  heading,
  trackingLocation,
}: MembershipBannerSectionProps) => {
  const trackingArgs = {
    contentType: 'membership banner|plp-membership-banner-noths&more',
    label: 'plp-membership-banner-noths&more',
    location: trackingLocation,
  };

  return (
    <div css={styles.wrapper}>
      <MembershipBanner
        buttonHref={buttonHref}
        buttonText={buttonText}
        copy={copy}
        heading={heading}
        onButtonClick={(e) => trackLinkClick(e, trackingArgs)}
        onVisible={() => trackImpression(trackingArgs)}
      />
    </div>
  );
};
