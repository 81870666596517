import { useEffect } from 'react';
import { minBreakpoints } from '@noths/polaris-client-ribbons-base';

import type { FilterNavType } from 'src/types/navigation';

export const useFilterNavType = (callback: (navType: FilterNavType) => void) => {
  useEffect(() => {
    let filterNavType: FilterNavType = 'sticky filter';
    if (window.innerWidth >= minBreakpoints.M) {
      filterNavType = 'collapsed sidebar';
    }
    if (window.innerWidth >= minBreakpoints.L) {
      filterNavType = 'fixed sidebar';
    }

    callback(filterNavType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
