/* istanbul ignore file */
import { minBreakpoints, theme } from '@noths/polaris-client-ribbons-base';
import { mediaQuery } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { spacing } = theme;

export const wrapper = css`
  margin-top: ${spacing.xxl}px;
  padding: 0 ${spacing.md}px;

  ${mediaQuery(
    { min: minBreakpoints.M },
    css`
      padding: 0;
    `,
  )}

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      margin-top: 40px;
    `,
  )}

    ${mediaQuery(
    { min: minBreakpoints.XL },
    css`
      margin-bottom: ${spacing.xxl}px;
    `,
  )}
`;
