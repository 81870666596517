import type { NonNullableLink } from '@noths/polaris-dev-ts-types';

export const getPartnerBreadcrumbs = (
  partnerName: string,
  partnerHomepageUrl: string,
  partnerHomepageRelativeUrl: string,
): NonNullableLink[] => {
  return [
    { rel: '/', title: 'Homepage', href: new URL(partnerHomepageUrl).origin },
    ...(partnerName && partnerHomepageUrl && partnerHomepageRelativeUrl
      ? [
          {
            rel: partnerHomepageRelativeUrl,
            title: partnerName,
            href: partnerHomepageUrl,
          },
        ]
      : []),
  ];
};
