import React from 'react';
import { Image } from '@noths/polaris-client-ribbons-design-system';

import { PARTNER_HEADING_SUFFIX } from './constants/copy';
import * as styles from './styles';

export interface PartnerHeaderProps {
  partnerLogoUrl: string;
  partnerName: string;
}

export const PartnerHeader = ({ partnerLogoUrl, partnerName }: PartnerHeaderProps) => (
  <>
    <Image alt="" css={styles.partnerImage} loading="lazy" src={partnerLogoUrl} />
    <h1 css={styles.partnerHeaderWrapper}>
      <span css={styles.partnerName}>{partnerName}&nbsp;</span>
      <span css={styles.headingSuffix}>{PARTNER_HEADING_SUFFIX}</span>
    </h1>
  </>
);
